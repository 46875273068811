/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //btn animación - inicio

        $( ".button_su_inner" ).mouseenter(function(e) {
         var parentOffset = $(this).offset(); 

         var relX = e.pageX - parentOffset.left;
         var relY = e.pageY - parentOffset.top;
         $(this).prev(".su_button_circle").css({"left": relX, "top": relY });
         $(this).prev(".su_button_circle").removeClass("desplode-circle");
         $(this).prev(".su_button_circle").addClass("explode-circle");

        });

        $( ".button_su_inner" ).mouseleave(function(e) {

         var parentOffset = $(this).offset(); 

         var relX = e.pageX - parentOffset.left;
         var relY = e.pageY - parentOffset.top;
         $(this).prev(".su_button_circle").css({"left": relX, "top": relY });
         $(this).prev(".su_button_circle").removeClass("explode-circle");
         $(this).prev(".su_button_circle").addClass("desplode-circle");

        });

        //btn animación - fin


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.owl-home').owlCarousel({
          loop:false,
          margin:0,
          nav:false,
          dots:false,
          mouseDrag:false,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
            },
            1000:{
              items:1
            }
          }
        });


        $('.slicktest').slick({
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          arrows:true,
          centerMode: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                
                slidesToShow: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: true,
                slidesToShow: 1
              }
            }
          ]
        });

        setTimeout(function(){ 
          $(".slicktest .slick-next").click();
          }, 500
        );

        $('.slicktest-mobile').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerPadding: '20px',
          arrows:true,
          centerMode: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: true,
                slidesToShow: 1
              }
            }
          ]
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'blog': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.owl-blog').owlCarousel({
          loop:true,
          margin:0,
          nav:false,
          dots:true,
          autoplay:true,
          autoplayTimeout:3000,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
            },
            1000:{
              items:1
            }
          }
        });

      }
    },

    //Vivero
    'vivero': {
      init: function() {

        mapboxgl.accessToken = 'pk.eyJ1IjoiYmFzaWtvZGV2IiwiYSI6ImNqbGZwM2tzNDBkaTkzd3BrZGF6emZyNGgifQ.LeD89tAO0biEK5j3nt_AJw';
        var map = new mapboxgl.Map({
        container: 'vivero-mapa', // container ID
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [-103.3906299313983, 20.709344014959903], // starting position [lng, lat]
        zoom: 14 // starting zoom
        });

        // Create a default Marker and add it to the map.
        var marker1 = new mapboxgl.Marker()
        .setLngLat([-103.3906299313983, 20.709344014959903])
        .addTo(map);


        


      }
    },


    //Sumate al proyecto
    'sumate': {
      init: function() {
      }
    },


    //Template
    'catalogo': {
      init: function() {
        $("#compraExitosa").modal("show");
      }
    }



  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
